import { useContext } from 'react';
import { debounce } from 'lodash-es';
import EmitEventsContext from '@/storychief/context/EmitEvents';

export default function useEmitEvent(eventName: string) {
  const context = useContext(EmitEventsContext);

  function emit(data = null): void {
    if (context) {
      context.setEvent(eventName, data);
    }
  }

  return debounce(emit, 1);
}
